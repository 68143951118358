import Breadcrumbs from 'components/Breadcrumbs';
import { Button } from 'components/buttons/Button/Button';
import './styles.scss';
import { Parallax } from 'react-parallax';
const parallaxValue = 100;
const style = {
    display: 'none',
}

const Banner = ({
    title = '',
    image,
    customH1 = '',
    buttonText,
    buttonType = 'learn-more',
    children = undefined,
    withCrumbs = true,
    onClickButton = undefined,
    to = undefined,
}) => {
    return (
        <section className="container">
            <div className="primary-section-wrapper">
                <div className="primary-section">
                    <div className="left-side">
                        {withCrumbs && <Breadcrumbs />}
                        {title ? (
                            <h1 className="banner-title h2">{title}</h1>
                        ) : (
                            <div className="left-side__content banner-title">{children}</div>
                        )}
                        {!title && customH1 ? (
                            <h1 style={style} className="banner-title h2">{customH1}</h1>
                        ) : (
                           <div></div>
                        )}
                       
                    </div>
                    <div className="button-wrapper">
                        <Button type={buttonType} onClick={onClickButton} href={to}>
                            {buttonText}
                        </Button>
                        <div className="smooth-corner1"></div>
                        <div className="smooth-corner2"></div>
                    </div>
                </div>
                <div className="banner-bg">
                    <Parallax
                        bgImage={image}
                        bgImageAlt={image}
                        strength={parallaxValue}
                        bgImageStyle={{
                            height: `calc(100% + ${parallaxValue}px)`,
                            objectFit: 'cover',
                        }}
                    />
                </div>
            </div>
            {buttonType.includes('find') && (
                <Button type="find-mobile" className="banner-bottom" onClick={onClickButton} href={to}>
                    {buttonText}
                </Button>
            )}
            {buttonType.includes('banner') && (
                <Button className="banner-bottom" onClick={onClickButton} href={to}>
                    {buttonText}
                </Button>
            )}
        </section>
    );
};
export default Banner;
